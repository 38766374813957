import React, { Fragment } from "react"
import { Breadcrumb, Container, Row, Col, Form, Button } from "react-bootstrap"
import bannerImg from "../assets/images/contact-banner.jpg"
import contactImg from "../assets/images/contact-img.svg"
import ContactForm from "../components/contact/ContactForm"
import LayoutAnimate from "../components/animation/LayoutAnimate"
import { Parallax } from "react-parallax"
const Contact = () => {
  return (
    <LayoutAnimate>
      <section className="page-banner-section">
        <Parallax
          bgImage={bannerImg}
          bgImageAlt="contact us"
          strength={200}
          className="cta-section"
          renderLayer={percentage => (
            <div
                style={{
                    position: 'absolute',
                    background: `linear-gradient(to bottom, rgba(2, 23, 32,0.1),rgba(2, 23, 32,0.6),rgba(2, 23, 32,0.7))`,
                    left: '0',
                    top: '0',
                    width: '100%',
                    height: '100%',
                }}
            />
        )}
        >
          <div className="page-banner-wrapper">
            <Container
              className="page-banner-title-container"
              data-sal-duration="1000"
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-delay="200"
            >
              <h2>Contact Us</h2>
            </Container>
            <div className="breadcrumnb-wrapper">
              <Container
                className="breadcrumb-container pb-3"
                data-sal-duration="1000"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="400"
              >
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
                </Breadcrumb>
              </Container>
            </div>
          </div>
        </Parallax>
      </section>
      <section className="contact-section layout-padding">
        <Container>
          <Row className="align-items-center">
            <Col
              md={6}
              data-sal-duration="1000"
              data-sal="slide-right"
              data-sal-easing="ease"
              data-sal-delay="200"
            >
              <img
                src={contactImg}
                alt="Contact Us image"
                className="w-100 img-fluid"
              />
            </Col>
            <Col
              md={6}
              data-sal-duration="1000"
              data-sal="slide-left"
              data-sal-easing="ease"
              data-sal-delay="200"
            >
              <div className="contact-form-wrapper mt-5 mt-md-0">
                <h2 className="mb-2">Get In Touch</h2>
                <p className="mb-4">To help you choose your products</p>
                <ContactForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </LayoutAnimate>
  )
}

export default Contact
