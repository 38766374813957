import Button from "@restart/ui/esm/Button"
import React, { useState } from "react"
import { Alert, Col, Form, Row } from "react-bootstrap"

const formDefaultVals = {
  firstName: "",
  lastName: "",
  email: "",
  message: "",
}

function FormExample() {
  const [validated, setValidated] = useState(false)
  const [formVals, setFormVals] = useState(formDefaultVals)
  const [formAlert, setFormAlert] = useState({ variant: "", msg: "" })

  const updateFormAlert = obj => {
    setFormAlert({ variant: obj.variant, msg: obj.msg })

    setTimeout(() => {
      setFormAlert({ variant: "", msg: "" })
    }, 5000);

  }

  const updateFormVals = e => {
    setFormVals({ ...formVals, [e.target.name]: e.target.value })
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    event.preventDefault()
    event.stopPropagation()

    const webUrl = "http://shanpetro.com/auth"
    const formId = 70

    if (form.checkValidity() === false) {
    } else {
      var formdata = new FormData()
      formdata.append("first-name", formVals.firstName)
      formdata.append("last-name", formVals.lastName)
      formdata.append("your-email", formVals.email)
      formdata.append("your-message", formVals.message)

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      const postForm = async () => {
        try {
          const response = await fetch(
            `${webUrl}/wp-json/contact-form-7/v1/contact-forms/${formId}/feedback`,
            requestOptions
          )
          // console.log(response)
          if(response.status === 200){
            updateFormAlert({
              variant: "success",
              msg: "Form submitted successfully!",
            })
            setFormVals(formDefaultVals) 
          }else{
            updateFormAlert({
              variant: "danger",
              msg: "Something went wrong, please try again",
            })
          } 
          
        } catch (error) {
          // console.log("error", error)
          updateFormAlert({
            variant: "danger",
            msg: "Something went wrong, please try again",
          })
        }
      }
      postForm();
    }

    
    setValidated(true)
  }

  return (
    <div>
      {/* onSubmit={handleSubmit} */}
      {formAlert.msg && (
        <Alert variant={formAlert.variant}>
          {formAlert.msg}
        </Alert>
      )}
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Form.Group
            as={Col}
            md="6"
            className="form-group"
            controlId="validationCustom01"
          >
            <Form.Control
              required
              type="text"
              value={formVals.firstName}
              placeholder="First name"
              name="firstName"
              onChange={updateFormVals}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            md="6"
            className="form-group"
            controlId="validationCustom01"
          >
            <Form.Control
              required
              type="text"
              value={formVals.lastName}
              placeholder="Last name"
              name="lastName"
              onChange={updateFormVals}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please enter ast name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            md={12}
            className="form-group"
            controlId="validationCustom01"
          >
            <Form.Control
              required
              placeholder="Email address"
              value={formVals.email}
              type="email"
              name="email"
              onChange={updateFormVals}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
            <Form.Control.Feedback type="invalid">
              Please email.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            as={Col}
            md={12}
            className="form-group"
            controlId="validationCustom01"
          >
            <Form.Control
              as="textarea"
              placeholder="Leave a comment here"
              style={{ height: "100px" }}
              value={formVals.message}
              name="message"
              onChange={updateFormVals}
            />
          </Form.Group>
          <Col md={12}>
            <Button className="ct-btn1" type="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default FormExample
